/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/layout';
import SectionContainer from '../components/SectionContainer';

const Privacy = props => (
  <Layout location={props.location}>
    <div className="conditions-page">
      <SectionContainer num={1} container>
        <h2 style={{ color: '#cf1a9c' }}>Privacy Policy</h2>
        <div className="content-block">
          <p>
            This Privacy Policy governs how Inkblot Technologies Inc.
            (&quot;Inkblot&quot;, &quot;us&quot; or &quot;we&quot;)
            collects, uses, discloses and otherwise manages your personal information, including
            your personal health information (collectively, &quot;personal information&quot;).
            This Privacy Policy applies to our privacy practices on (1) the Inkblot website
            located at www.inkblottherapy.com (the &quot;Website&quot;); and (2) all other services
            provided by Inkblot, as described on the Website (collectively, the
            &quot;Platform&quot;).
            <br />
            <br /> For information on the general terms and conditions that apply to individuals
            (the &quot;Terms of Use&quot;) using the Platform and Services (as defined hereafter),
            please see our Terms of Use.
            <br />
            <br /> The terms of this Privacy Policy are incorporated into, and considered a part of,
            the Terms of Use. This Privacy Policy relates to use of the Platform. In the event that
            any additional or specific terms are inconsistent with the terms contained in this
            Privacy Policy, such as the terms and conditions prescribed by the Terms of Use [NTD:
            Add hyperlink to Terms of Use.], the Terms of Use shall prevail.
            <br />
            <br /> THE Platform MAY COLLECT AND TRANSMIT PERSONAL INFORMATION ABOUT YOU. By accessing
            THE platform you accept all the terms and conditions of this Privacy Policy. In
            particular, by submitting your personal data to us, you will be deemed to have given your
            permission for THE use and disclosures referred to herein.
            <br />
            <br /> If you do not agree with the terms and conditions of this Privacy Policy, you must
            immediately cease using and accessing THE PLATFORM and any content, services or products
            contained in or available from THE PLATFORM for which you need to provide personal
            information.
          </p>
        </div>
        <div className="title">
          <p>Collection and Use of Personal Information</p>
        </div>
        <div className="content-block">
          <p>
            Inkblot provides users (&quot;Users&quot;) on-demand access to mental health counsellors
            (&quot;Counsellors&quot;) by secure real-time text and video communication for mental health
            consultations (&quot;Counselling Services&quot;), and to support staff (&quot;Support Staff&quot;) for related
            administrative support services (&quot;Support Services&quot;, and together with Counselling
            Services and other services that may be provided by Inkblot, &quot;Services&quot;). For the purposes
            of providing Services and purposes consistent with those purposes, Inkblot and Counsellors
            may collect and use personal information that is provided by clients during telehealth
            consultations or uploaded by clients to the Platform to complete and update online
            profiles and personal health histories maintained in secure client user accounts (each, a
            &quot;User Account&quot;). Your personal information may include information or records regarding
            your medical or health history, health status and laboratory testing results, diagnostic
            images, and other health related information. Your personal information may be contained
            in medical records, treatment and examination notes, and other health related records
            maintained by Counsellors. Neither Inkblot nor Counsellors collects or uses personal
            information for any other purpose than providing and facilitating the provision of
            Services, and purposes consistent with those purposes, including but not limited to the
            following:
            <br />
            <br /> Register: You do not have to register in order to use our Platform. However, in
            order to use the Platform to receive Services, you will need to register to create a User
            Account and provide your name, contact information and a password that you select. You
            will also need to disclose your personal information to Counsellors and Support Staff in
            order to enable them to provide appropriate Services. We use your personal information to
            identify who you are, communicate with you, and otherwise administer your use of the
            Platform to receive Services. Persons under 15 years old: We do not knowingly allow
            individuals under the age 15 to create their own User Accounts that allow access to our
            Services. Individuals under the age of 15 may access the Platform or receive Services at
            the discretion of Counsellors using their parent or legal guardian’s User Account and in
            the presence of that parent or legal guardian. Inkblot does not intentionally collect
            personal data of persons under the age of 15 years old. If we discover that we have
            collected personal information from a child under the age of 15 years old, we will delete
            that information immediately. Purchase of Services: If you choose to purchase Services, we
            may collect your payment information such as your name, address, phone number, e-mail
            address, billing address, and payment method information. This information is used to
            process and maintain your subscription. Quality of Care: If you access the Platform or
            receive Services, your personal information may be used to improve the quality of Services
            provided by Counsellors. Your personal information may also similarly be used to create
            de-identified information such as aggregate statistics relating to the use of the Platform
            and Services. Contact Us: When you contact us with a comment, question or complaint, you
            may be asked for information that identifies you (such as your name, address and a
            telephone number) along with additional information we need to help us promptly answer
            your question or respond to your comment. We may retain this information to assist you in
            the future and to improve our customer service, service offerings, and our Services.
          </p>
        </div>
        <div className="title">
          <p>Disclosure of Personal Information</p>
        </div>
        <div className="content-block">
          <p>
            We will not disclose, trade, rent, sell or otherwise transfer personal information,
            without consent, except as otherwise set out herein or in our Terms of Use.
            <br />
            <br /> We may transfer or disclose personal information as follows:
            <br />
            <br /> Circle of Care: If you access the Platform or receive Services, your personal
            information may be disclosed to and among Counsellors and Support Staff for the purpose of
            providing or assisting in the provision of Services to you. Your personal information may
            be provided to third parties such as other health professionals, specialists, consultants
            or pharmacists, pharmacies and laboratories for the purpose of providing or assisting in
            the provision of Services to you, with your prior written consent. Service Provider
            Arrangements: In connection with the provision of our Platform and Services, we may
            disclose (or otherwise make available) personal information to third parties who provide
            Services on our behalf. For example, we may use service providers to process payments,
            host our website and store information on our behalf. Personal information may be
            maintained and processed by third party service providers in Canada and may be accessed by
            the courts, law enforcement and national security authorities as required under the laws
            of this jurisdiction. Our service providers are given the information they need to perform
            their designated functions, and we do not authorize them to use or disclose personal
            information for their own marketing or other purposes. For more information about the way
            in which our service providers treat personal information, contact us as set out in the
            &quot;Contact Us&quot; section below. Sale of Business: Inkblot may transfer personal information as
            an asset in connection with a merger or sale (including transfers made as part of
            insolvency or bankruptcy proceeding) involving all or part of Inkblot or as part of a
            corporate reorganization or other change in corporate control. Legal: Inkblot and our
            affiliates and service providers may disclose personal information to third parties as
            required or permitted by applicable law. For greater certainty, Inkblot will disclose your
            personal information to the extent that it is legally required or necessary for the
            establishment, exercise or defence of legal claims and legal process; or in the event of
            an emergency pertaining to your health and/or safety or that of a Counsellor. Where a
            disclosure of our clients’ information is required by applicable law, we promptly notify
            our client prior to complying with such requirements (to the extent we are not prohibited
            from doing so). We may also disclose personal information where necessary for the
            establishment, exercise or defence of legal claims and to investigate or prevent actual or
            suspected loss or harm to persons or property.
          </p>
        </div>
        <div className="title">
          <p>Information about Our Services</p>
        </div>
        <div className="content-block">
          <p>
            As you access the Platform or receive Services, certain information may be passively
            collected by cookies (&quot;Cookies&quot;), navigational data like Uniform Resource Locators
            (&quot;URLs&quot;) and third party tracking services, including:
            <br />
            <br /> Activity Information: We may keep track of some of the actions you take on the
            Platform, such as the content of searches you perform on the Platform, solely for the
            purpose of internal evaluation of Platform effectiveness. This information will not be
            shared with third parties. Access Device and Browser Information: When you access the
            Platform from a computer or other device, we may collect anonymous information from that
            device, such as your Internet protocol address, browser type, connection speed and access
            times (collectively, &quot;Anonymous Information&quot;). Cookies: Our Services uses a technology
            called Cookies. A Cookie is a tiny element of data that our Services send to a user’s
            browser, which may then be stored on the user’s hard drive so that we can recognize the
            user when they return. We use Cookies to remember your preferences and to authenticate
            you. You may set your browser to notify you when you receive a Cookie or to not accept
            certain cookies. However, if you decide not to accept Cookies from our Platform, you may
            not be able to take advantage of all of the Platform or Services features. Mobile
            Services: We may also collect non-personal information from your mobile device or
            computer. This information is generally used to help us deliver the most relevant
            information to you. Examples of information that may be collected and used include how you
            use the application(s) and information about the type of device or computer you use. In
            addition, in the event our application(s) crashes on your mobile device we will receive
            information about your mobile device model software version and device carrier, which
            allows us to identify and fix bugs and otherwise improve the performance of our
            application(s). Third Party Links: Our Platform may contain links to other websites or
            platforms that Inkblot does not own or operate. Also, links to our Platform may be
            featured on third party websites or platforms on which we advertise. Except as provided in
            this Privacy Policy, we will not provide personal information to these third parties
            without consent. We provide links to third party websites or platforms as a convenience to
            the user. These links are not intended as an endorsement of or referral to the linked
            websites or platforms. The linked websites or platforms have separate and independent
            privacy statements, notices and terms of use. We do not have any control over such
            websites or platforms, and therefore we have no responsibility or liability for the manner
            in which the organizations that operate such linked websites or platforms may collect, use
            or disclose, secure and otherwise treat personal information. Public Forums: If at any
            time the Services offer any type of notice board facilities, newsgroups, publicly
            accessible blogs, community forums, etc. (collectively, the &quot;Public Forums&quot;) we may
            collect the information that you disclose thereon. Such information may include, but is
            not limited to, your name, e-mail address, the messages you write or the content you
            choose. We may also use this information in order to help administrate the Public Forums.
            Such information will be used in accordance with this Privacy Policy. Please note that we
            cannot be responsible for other parties’ use of the personal information which you make
            available to such third parties through any of the Public Forums.
          </p>
        </div>
        <div className="title">
          <p>Security of Personal Information</p>
        </div>
        <div className="content-block">
          <p>
            We understand that data security is a critical issue for our clients and we are committed
            to safeguarding the personal information in our custody and under our control.
            <br />
            <br /> Inkblot has implemented a comprehensive information security program that includes
            written policies and procedures, and security controls. We have implemented reasonable
            administrative, technical and physical safeguards in an effort to protect against
            unauthorized access, use, modification and disclosure of personal information in our
            custody and control.
            <br />
            <br /> Our privacy practices are intended to comply with applicable privacy laws,
            including, without limitation, the Personal Health Information Protection Act (Ontario)
            (&quot;PHIPA&quot;) and the Personal Information Protection and Electronic Documents
            Act(Canada) (&quot;PIPEDA&quot;). We will maintain the privacy of your personal
            information as required by applicable privacy laws, including without limitation,
            PHIPA and PIPEDA and the regulations under those Acts.
          </p>
        </div>
        <div className="title">
          <p>Access to Personal Information</p>
        </div>
        <div className="content-block">
          <p>
            If we receive a request to access or update personal information we have collected on
            behalf of a particular client, we will direct the party making this request to the
            relevant client. We will assist our clients wherever possible in responding to such
            access requests.
            <br />
            <br /> If you submit personal information via our Platform or otherwise provide us with
            your personal information, you may request access, updating or correction of your
            personal information by submitting a written request to support@inkblottherapy.com.
            We may request certain personal information for the purposes of verifying your identity.
          </p>
        </div>
        <div className="title">
          <p> De-Identified Information</p>
        </div>
        <div className="content-block">
          <p>
            We may use de-identified information created by us from your personal information
            without restriction.
          </p>
        </div>
        <div className="title">
          <p>Limitation on Deletion</p>
        </div>
        <div className="content-block">
          <p>
            You may request deletion of your personal information by us, but please note that we may
            be required (by law or otherwise) to keep this information and not delete it (or to keep
            this information for a certain time, in which case we will comply with your deletion
            request only after we have fulfilled such requirements). When we delete personal
            information, it will be deleted from the active database, including from our archives,
            though we may also retain Anonymous Information about your use of our Platform and
            Services. Once we disclose some of your personal information to third parties, we may
            not be able to access that personal information any longer as maintained by the third
            party and cannot force the deletion or modification of any such information by the
            parties to whom we have made those disclosures. After we delete personal information,
            we will retain de-identified information and will continue to use de-identified
            information without restriction as permitted under this Privacy Policy.
          </p>
        </div>
        <div className="title">
          <p>Report Violations</p>
        </div>
        <div className="content-block">
          <p>
            You should report any security violations to us by sending an email to
            support@inkblottherapy.com.
          </p>
        </div>
        <div className="title">
          <p>Changes</p>
        </div>
        <div className="content-block">
          <p>
            This Privacy Policy may be updated periodically to reflect changes to our personal
            information practices. The revised Privacy Policy will be posted on the Platform. If we
            make any substantial changes to this Privacy Policy and the way in which we use your
            personal data, we will post these changes on this page and will do our best to notify
            you of any significant changes. Please check our Privacy Policy on a regular basis.
          </p>
        </div>
        <div className="title">
          <p>Contact Us</p>
        </div>
        <div className="content-block">
          <p>
            If you would like to contact Inkblot about our Privacy Policy,
            you can do so by contacting us by emailing support@inkblottherapy.com.
          </p>
        </div>
      </SectionContainer>
    </div>
  </Layout>
);

Privacy.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Privacy;
